
import Vue from 'vue'
import { KwikMediumContainer, KwikPageToolbar } from 'kwik-vue'
import { dispatchGetCustomerRequests } from '../store/actions'
import FFCustomerRequestCard from '../components/FFCustomerRequestCard.vue'
import { readCustomerRequests } from '../store/getters'
import { ICustomerRequest } from '../types'

export default Vue.extend({
  name: 'FFCustomerRequests',
  components: { KwikMediumContainer, FFCustomerRequestCard, KwikPageToolbar },
  data () {
    return {
      loading: true,
      currentView: 'OPEN',
      openCount: 0,
      activeCount: 0,
      winCount: 0,
      breadcrumbs: [
        { text: 'Food Factory', disabled: true },
        { text: 'Richieste clienti', disabled: false }
      ]
    }
  },
  computed: {
    customerRequests () {
      return readCustomerRequests(this.$store)
    }
  },
  methods: {
    placed () {
      dispatchGetCustomerRequests(this.$store)
    }
  },
  watch: {
    customerRequests: {
      handler (val: ICustomerRequest | null) {
        if (!val) {
          this.openCount = 0
          this.activeCount = 0
          this.winCount = 0
        } else {
          this.openCount = val.notOffered.length
          this.activeCount = val.offered.length
          this.winCount = val.won.length
        }
      },
      deep: true
    }
  },
  async mounted () {
    await dispatchGetCustomerRequests(this.$store)
  }
})
