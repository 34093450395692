
import { KwikDialog } from 'kwik-vue'
import { clockToFormatDate } from '@/utils/clockManager'
import Vue, { PropType } from 'vue'
import { ICustomerRequestItem } from '../types'
import { IOfferPayload } from '@/modules/offering/types'
import { dispatchPlaceOffer } from '@/modules/offering/store/actions'
import FFDateTimePicker from '@/components/FFDateTimePicker.vue'

export default Vue.extend({
  name: 'FFCustomerRequestCard',
  components: { KwikDialog, FFDateTimePicker },
  props: {
    customerRequest: {
      type: Object as PropType<ICustomerRequestItem>
    },
    canPlaceOffer: {
      type: Boolean,
      default () {
        return true
      }
    }
  },
  data () {
    return {
      showPlaceOffer: false,
      valid: false,
      offerQuantity: 0,
      offerPrice: 0,
      offerDueDate: 0,
      offerProductExpiration: 0
    }
  },
  methods: {
    clockToFormatDate (clock: number) {
      return clockToFormatDate(clock, 'shortDateTime')
    },
    async save () {
      const form: any = this.$refs.form
      if (!form.validate()) return
      const offerPayload: IOfferPayload = {
        customer_request_id: this.customerRequest.id,
        quantity: this.offerQuantity,
        quality: -1,
        unit_price: this.offerPrice,
        due_date: this.offerDueDate,
        product_expiration: this.offerProductExpiration
      }
      await dispatchPlaceOffer(this.$store, offerPayload)
      this.$emit('placed')
      this.showPlaceOffer = false
    },
    clearOffer () {
      this.offerQuantity = this.customerRequest.requested_quantity
      this.offerPrice = this.customerRequest.requested_price
      this.offerDueDate = this.customerRequest.requested_due_date
      this.offerProductExpiration = this.customerRequest.requested_product_expiration
    },
    closeOffer () {
      this.showPlaceOffer = false
      this.clearOffer()
    },
    startOffer () {
      this.showPlaceOffer = true
    }
  },
  mounted () {
    this.clearOffer()
  },
  watch: {
    offerDueDate: {
      handler (val) {
        console.log(val)
      }
    }
  }
})
